import openSocket from "socket.io-client";
import { isObject } from "lodash";

const backendUrls = {
  "main": process.env.REACT_APP_BACKEND_URL,
  "web": process.env.REACT_APP_WEB_BACKEND_URL,
  "official": process.env.REACT_APP_OFFICIAL_BACKEND_URL,
}

export function socketConnection(params, modules = ["main", "web", "official"]) { 
  const sockets = {};
  const connectedUrls = {};

  modules.forEach((module) => {
    let userId = null;
    if (localStorage.getItem("userId")) {
      userId = localStorage.getItem("userId");
    }

    const backendUrl = backendUrls[module];

    if (connectedUrls[backendUrl]) {
      return;
    }

    const urlParts = backendUrl.split("/");
    const path =
      urlParts.length > 3
        ? `/${urlParts.slice(3).join("/")}/socket.io`
        : "/socket.io";

    const socket = openSocket(backendUrl, {
      transports: ["websocket", "polling", "flashsocket"],
      pingTimeout: 18000,
      pingInterval: 18000,
      path,
      query: isObject(params) ? { ...params } : { userId: params.userId },
    });

    socket.module = module;
    sockets[module] = socket;
    connectedUrls[backendUrl] = socket;
  });

  const unifiedSocket = {
    on(event, callback) {
      Object.values(sockets).forEach((socket) =>
        socket.on(event, (...args) => callback(...args, { socket: socket, module: socket.module }))
      );
    },
    onAny(callback) {
      Object.values(sockets).forEach((socket) =>
        socket.onAny((event, ...args) => callback(event, ...args, { socket: socket, module: socket.module }))
      );
    },
    emit(event, data) {
      Object.values(sockets).forEach((socket) => socket.emit(event, data));
    },
    off(event, callback) {
      Object.values(sockets).forEach((socket) =>
        socket.off(event, callback)
      );
    },
    disconnect() {
      Object.values(sockets).forEach((socket) => socket.disconnect());
    },
    connect() {
      Object.values(sockets).forEach((socket) => socket.connect());
    },
  };

  return unifiedSocket;
}
