import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL
});

export const webApi = axios.create({
	baseURL: process.env.REACT_APP_WEB_BACKEND_URL,
	withCredentials: true,
})

export const officialApi = axios.create({
	baseURL: process.env.REACT_APP_OFFICIAL_BACKEND_URL,
	withCredentials: true,
})

export default api;
