import React, { createContext, useContext, useEffect } from "react";

import useSettings from "../../hooks/useSettings/index";
import { AuthContext } from "../Auth/AuthContext.js";
import { socketConnection } from "../../services/socket";
import { useLocalStorage } from "../../hooks/useLocalStorage/index.js";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
	const [settings, setSettings] = useLocalStorage("settings", {});
	const { getAll: getSettings } = useSettings();
  const { user } = useContext(AuthContext);


	useEffect(() => {
		let isMounted = true;

		async function loadSettings() {
			const settings = await getSettings();
			const settingsObj = settings.reduce((acc, setting) => {
				acc[setting.key] = setting.value;
				return acc;
			}, {});
			isMounted && setSettings(settingsObj);
		}

		if (user.id) {
			loadSettings();

			const companyId = localStorage.getItem("companyId");
			const socket = socketConnection({ companyId });
	
			socket.on(`company-${companyId}-settings`, (data) => {
				if (data.action === "update") {
					setSettings((prevState) => {
						return {
							...prevState,
							[data.setting.key]: data.setting.value
						}
					});
				}
			});
	
			return () => {
				socket.disconnect();
				isMounted = false;
			};
		}

		return () => {
			isMounted = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	
	return (
		<SettingsContext.Provider
			value={{ settings }}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export { SettingsContext, SettingsProvider };
