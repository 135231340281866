import React, { useState, useEffect, useContext, useRef } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import { webApi } from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import {
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const useStyles = makeStyles(theme => ({
  online: {
    fontSize: 11,
    color: "#25d366"
  },
  offline: {
    fontSize: 11,
    color: "#e1306c"
  },
  userMessage: {
    width: "100%",
    height: "100px"
  },
  inputFile: {
    display: "none"
  },
  fileName: {
    marginLeft: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const NewTicketFasterModal = ({ modalOpen, onClose, initialContact }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const [userMessage, setUserMessage] = useState(null);
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);
  const { companyId, whatsappId } = user;
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const [number, setNumber] = useState("");
  const phoneNumberRef = useRef(null);

  useEffect(() => {
    const handlePaste = event => {
      const items = event.clipboardData.items;
      for (let item of items) {
        if (item.kind === "file") {
          const file = item.getAsFile();
          setFile(file);
          simulateFileInputChange(file);
          event.preventDefault();
          break;
        }
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        phoneNumberRef.current.focus();
      }, 100);
    }
  }, [modalOpen]);

  const simulateFileInputChange = file => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    if (!fileInputRef.current) return;
    fileInputRef.current.files = dataTransfer.files;
    fileInputRef.current.dispatchEvent(new Event("change", { bubbles: true }));
  };

  const handleClose = () => {
    onClose();
    setUserMessage("");
    setNumber("");
    setFile(null);
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSaveTicket = async () => {
    setLoading(true);
    try {
      const whatsappId = selectedWhatsapp !== "" ? selectedWhatsapp : null;

      if (number === "" || number.length < 10) {
        toast.error("Digite um número de telefone válido");
        return;
      }

      if (userMessage === null) {
        toast.error("Digite uma mensagem para abrir o ticket");
        return;
      }

      if (whatsappId === null) {
        toast.error("Selecione uma conexão para abrir o ticket");
        return;
      }

      if (file && file.size > MAX_FILE_SIZE) {
        toast.error("O arquivo anexado é muito grande. O tamanho máximo é 10MB");
        return;
      }

      const formData = new FormData();
      formData.append("number", number);
      formData.append("userId", user.id);
      formData.append("whatsappId", whatsappId);
      formData.append("message", userMessage);
      if (file) {
        formData.append("file", file);
      }

      const response = await webApi.post("/tickets", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      handleClose();
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = value => {
    return value.replace(/\D/g, "");
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">
        {i18n.t("newTicketModal.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid style={{ width: 300 }} container spacing={2}>
          {/* CONTATO */}
          <Grid xs={12} item>
            <TextField
              fullWidth
              variant="outlined"
              label="Telefone"
              inputRef={phoneNumberRef}
              value={number}
              onChange={e => {
                const formattedValue = formatNumber(e.target.value);
                setNumber(formattedValue);
              }}
            />
          </Grid>

          <Grid xs={12} item>
            <TextareaAutosize
              className={classes.userMessage}
              fullWidth
              variant="outlined"
              placeholder="Mensagem"
              value={userMessage}
              onChange={e => setUserMessage(e.target.value)}
              minRows={6}
            />
            <Select
              required
              fullWidth
              displayEmpty
              variant="outlined"
              value={selectedWhatsapp}
              onChange={e => {
                setSelectedWhatsapp(e.target.value);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              renderValue={() => {
                if (selectedWhatsapp === "") {
                  return "Selecione uma Conexão";
                }
                const whatsapp = whatsApps.find(w => w.id === selectedWhatsapp);
                return whatsapp.name;
              }}
            >
              {whatsApps?.length > 0 &&
                whatsApps.map(
                  (whatsapp, key) =>
                    whatsapp.status === "CONNECTED" && (
                      <MenuItem dense key={key} value={whatsapp.id}>
                        <ListItemText
                          primary={
                            <>
                              {/* {IconChannel(whatsapp.channel)} */}
                              <Typography
                                component="span"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "10px",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  lineHeight: "2"
                                }}
                              >
                                {whatsapp.name} &nbsp;{" "}
                                <p
                                  className={
                                    whatsapp.status === "CONNECTED"
                                      ? classes.online
                                      : classes.offline
                                  }
                                >
                                  ({whatsapp.status})
                                </p>
                              </Typography>
                            </>
                          }
                        />
                      </MenuItem>
                    )
                )}
            </Select>
            <div>
              <div>
                <input
                  type="file"
                  className={classes.inputFile}
                  id="upload-file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <label htmlFor="upload-file">
                  <IconButton
                    color="primary"
                    component="span"
                    className={classes.button}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </label>
                <Typography
                  variant="body2"
                  component="span"
                  color="textSecondary"
                >
                  Anexar arquivo
                </Typography>
              </div>
              <div>
                {file && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.fileName}
                  >
                    {file.name}
                  </Typography>
                )}
              </div>
            </div>

            {/* <div className={classes.inputLabel}>
              <input
                type="file"
                className={classes.inputFile}
                id="upload-file"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loading}
          variant="outlined"
        >
          {i18n.t("newTicketModal.buttons.cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          type="button"
          onClick={() => handleSaveTicket()}
          color="primary"
          jn
          loading={loading}
        >
          {i18n.t("newTicketModal.buttons.ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};
export default NewTicketFasterModal;
