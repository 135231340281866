import Dexie from "dexie";
import moment from "moment";
import api from "./api";

const db = new Dexie("nexus-chat", { cache: 'immutable' });

db.version(1).stores({
  tickets: "++id,uuid,[status+updatedAt],[status+userId+updatedAt],contactId,updatedAt",
  messages: "++id,[ticketId+createdAt]"
});

export async function syncData() {
  const newestTicket = await db.tickets
    .orderBy("updatedAt")
    .reverse()
    .first();

  const newestTimestamp = newestTicket
    ? new Date(newestTicket.updatedAt).getTime().toString()
    : "0";

  const { tickets, messages } = await fetchTicketsSyncData({
    since: newestTimestamp
  });

  const promises = [];

  for (const ticket of tickets) {
    promises.push(saveTicket(ticket));
  }

  for (const message of messages) {
    promises.push(saveMessage(message));
  }

  await Promise.all(promises);
}

export async function loadMoreTickets(status) {
  const oldestTicket = await db.tickets.where("status").equals(status).first();

  const oldestTimestamp = oldestTicket
    ? new Date(oldestTicket.updatedAt).getTime().toString()
    : new Date().getTime().toString();

  const { tickets, messages } = await fetchTicketsSyncData({
    until: oldestTimestamp,
    status,
  });

  const promises = [];

  for (const ticket of tickets) {
    promises.push(saveTicket(ticket));
  }

  for (const message of messages) {
    promises.push(saveMessage(message));
  }

  await Promise.all(promises);

  return tickets.length > 0;
}

export async function fetchTicketsSyncData({
  since,
  until,
  status,
}) {
  const params = new URLSearchParams();
  since && params.append("since", since);
  until && params.append("until", until);
  status && params.append("status", status);

  const response = await api.get(`/tickets/sync?${params}`);

  const tickets = response.data;

  await new Promise(resolve => setTimeout(resolve, 1000));

  const messages = response.data.reduce((acc, ticket) => {
    acc.push(...ticket.messages);
    delete ticket.messages;
    return acc;
  }, []);

  return { 
    tickets, 
    messages 
  };
}

export function queryTickets({ status, showAll, userId, selectedQueueIds, userQueues }) {
  const where = {};

  if (status) {
    where.status = status;
  }

  if (!showAll) {
    where.userId = userId;
  }

  let collection;

  if (Object.keys(where).length === 0) {
    collection = db.tickets.orderBy("updatedAt");
  } else {
    collection = db.tickets.where(where);
  }

  const settings = JSON.parse(localStorage.getItem("settings") || '{}');
  const userQueueIds = userQueues.map(queue => queue.id);

  function transferQueueCondition(ticket) {
    if (settings.restrictSystemQueuesVisibility !== "enabled") {
      return true;
    }

    if (!["BOT", "ARW", "ARO"].includes(ticket.queue?.name)) {
      return true;
    }

    return !ticket.transferQueueId || userQueueIds.indexOf(ticket.transferQueueId) > -1;
  }

  collection = collection.and((ticket) => 
    (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&
    transferQueueCondition(ticket)
  );

  return collection;
}

export async function saveTicket(ticket) {
  await db.transaction("rw", db.tickets, async () => {
    const exists = await db.tickets.get(ticket.id);
    if (exists) {
      let shouldUpdate = 
        !ticket.updatedAt || 
        !exists.updatedAt || 
        new Date(exists.updatedAt) < new Date(ticket.updatedAt);

      if (shouldUpdate) {
        await db.tickets.update(ticket.id, ticket);
      }
    } else {
      await db.tickets.add(ticket);
    }
  });
}

export async function saveTickets(tickets) {
  await Promise.all(tickets.map(ticket => saveTicket(ticket)));
}

export async function saveMessage(message) {
  await db.transaction("rw", db.messages, async () => {
    const exists = await db.messages.get(message.id);
    if (exists) {
      if (new Date(exists.updatedAt) < new Date(message.updatedAt)) {
        await db.messages.update(message.id, message);
      }
    } else {
      await db.messages.add(message);
    }
  });
}

export async function pruneData() {
  // open, pending, paused tickets: never
  // closed tickets: 100 tickets
  // messages limit: 30 messages per ticket

  const closedTicketLimitCount = 100;
  const messageLimitCount = 30;

  const ticketsToDelete = await db.tickets
    .where("status")
    .equals("closed")
    .reverse()
    .offset(closedTicketLimitCount)
    .toArray();


  await Promise.all(
    ticketsToDelete.map(async (ticket) => {
      await db.tickets.delete(ticket.id);
      await db.messages.where("ticketId").equals(ticket.id).delete();
    })
  );

  const allTickets = await db.tickets.toArray();

  await Promise.all(
    allTickets.map(async (ticket) => {
      const ticketMessageCount = await db.messages
        .where("ticketId")
        .equals(ticket.id)
        .count();

      if (ticketMessageCount > messageLimitCount) {
        await db.messages
          .where("ticketId")
          .equals(ticket.id)
          .limit(ticketMessageCount - messageLimitCount)
          .delete();
      }
    })
  );
}

export async function eraseDatabase() {
  await db.delete({ disableAutoOpen: false });
}

export default db;