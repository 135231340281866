import React, { useState, useEffect, useContext, memo } from "react";
import { Virtuoso } from 'react-virtuoso'

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import { useLiveQuery } from "dexie-react-hooks";
import { loadMoreTickets, queryTickets } from "../../services/db";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    width: '100%',
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    overflowY: "scroll",
    overflowX: "hidden",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TicketsListCustom = (props) => {
  const {
    status,
    showAll,
    selectedQueueIds,
    style,
  } = props;

  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { setStatusTickets } = useContext(TicketsContext);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const ticketsList = useLiveQuery(async () => {
    const userId = user?.id;
    const userQueues = user?.queues || [];

    const collection = await queryTickets({
      status,
      showAll,
      userId,
      selectedQueueIds,
      userQueues,
    })
    
    const tickets = await collection.toArray();

    return [].concat(tickets).reverse();
  }, [showAll, selectedQueueIds]);

  const loading = !ticketsList;

  useEffect(() => {
    if (!ticketsList) return;
    setStatusTickets(status, ticketsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);


  async function loadMore() {
    setIsLoadingMore(true);

    try {
      const loaded = await loadMoreTickets(status);
      if (!loaded) setHasNextPage(false);
    } finally {
      setIsLoadingMore(false);
    }
  }

  const loadMoreItems = !ticketsList || isLoadingMore || !hasNextPage 
    ? () => {} 
    : loadMore;

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      {!loading && !isLoadingMore && ticketsList?.length === 0 ? (
        <div className={classes.noTicketsDiv}>
          <span className={classes.noTicketsTitle}>
            {i18n.t("ticketsList.noTicketsTitle")}
          </span>
          <p className={classes.noTicketsText}>
            {i18n.t("ticketsList.noTicketsMessage")}
          </p>
        </div>
      ) : (
        <Virtuoso
          style={{ width: "100%", height: "100%" }}
          className={classes.ticketsList}
          data={ticketsList}
          endReached={loadMoreItems}
          itemContent={renderRow}
          components={{
            Footer: () => {
              if (isLoadingMore || !ticketsList) {
                return (
                  <>
                    <TicketsListSkeleton />
                    <TicketsListSkeleton />
                    <TicketsListSkeleton />
                  </>
                );
              }
              return null;
            },
          }}
        >
        </Virtuoso>
      )}
    </Paper>
  );
};

const MemoizedTicketListItem = memo(TicketListItem, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.ticket) === JSON.stringify(nextProps.ticket);
});

const renderRow = (_, ticket) => {
  return <MemoizedTicketListItem ticket={ticket} />;
}

export default TicketsListCustom;
